<script>
import consolesService from '@/services/consoles.service'
import brandsService from '@/services/brands.service'
import generationsService from '@/services/generations.service'
import mediaService from '@/services/media.service'
import consoleTypes from '@/data/enums/consoleTypes.enum'
import lazyType from '@/utils/lazy-type'

export default {
  name: 'Consoles',
	data() {
		return {
      items: [],
      limit: 20,
      offset: 1,
      totalItems: 0,
      activeCategoryTitle: this.$t('allCategories'),
      activeTypeTitle: this.$t('allTypes'),
      activeBrandTitle: this.$t('allBrands'),
      activeGenerationTitle: this.$t('allGenerations'),
      activeMediumTitle: this.$t('allMedia'),
      types: consoleTypes,
      categories: [],
      brands: [],
      generations: [],
      media: [],
      selectedCategory: null,
      selectedType: null,
      selectedBrand: null,
      selectedGeneration: null,
      selectedMedium: null,
      keyword: "",
      lazyTypeDelay: 200,
      headerFields: [
        "__slot:checkboxes",
        {
          name: "id",
          label: 'ID',
          sortable: true,
        },
        {
          name: "published",
          label: this.$t('published'),
          customElement: "published",
          sortable: true,
        },
        {
          name: "photo",
          label: this.$tc('photo'),
          customElement: "photo",
        },
        {
          name: "title",
          label: this.$t('title'),
          customElement: "title",
          sortable: true,
        },
        {
          name: "category",
          label: this.$tc('category'),
          format: val => val['name'],
          sortable: true,
        },
        {
          name: "brand",
          label: this.$tc('brand'),
          format: brands => brands.map(brand => brand.name).join(', '),
          sortable: true,
        },
        {
          name: "media",
          label: this.$tc('medium', 2),
          format: media => media.map(medium => medium.name).join(', '),
        },
        {
          name: "generation",
          label: this.$tc('generation'),
        },
        {
          name: "changed_at",
          label: this.$t('lastUpdated'),
          format: date => new Date(date).toLocaleString('en-GB'),
        },
        "__slot:actions:edit",
        "__slot:actions:delete",
      ],
		}
  },
	mounted() {
    this._get()
    this._getCategories()
    this._getBrands()
    this._getGenerations()
    this._getMedia()
	},
	methods: {
    lazyType,
    _get(resetOffset = true) {
      if(resetOffset) this.offset = 1
			consolesService.getAll({category: this.selectedCategory, type: this.selectedType, brand: this.selectedBrand, generation: this.selectedGeneration, medium: this.selectedMedium, limit: this.limit, offset: this.offset, keyword: this.keyword}).then(
				response => {
          this.items = response.data.map(item => {
            item.changed_at = item.updated_at || item.created_at
            return item
          })
          this.totalItems = Number(response.headers['x-items-count']) || 0
				}
			)
    },
    _getCategories() {
      consolesService.getCategories()
        .then(res => {
          this.categories = res.data
        })
    },
    _getBrands() {
      brandsService.getAll({})
        .then(res => {
          this.brands = res.data
        })
    },
    _getGenerations() {
      generationsService.getAll()
        .then(res => {
          this.generations = res.data
        })
    },
    _getMedia() {
      mediaService.getAll()
        .then(res => {
          this.media = res.data
        })
    },
    filterByCategory(category) {
      this.activeCategoryTitle = category || this.$t('allCategories')
      this.selectedCategory = category
      this._get()
    },
    filterByType(type) {
      this.activeTypeTitle = type || this.$t('allTypes')
      this.selectedType = type
      this._get()
    },
    filterByBrand(brand) {
      this.activeBrandTitle = brand.name || this.$t('allBrands')
      this.selectedBrand = brand.alias
      this._get()
    },
    filterByGeneration(generation) {
      this.activeGenerationTitle = generation.name || this.$t('allGenerations')
      this.selectedGeneration = generation.id
      this._get()
    },
    filterByMedium(medium) {
      this.activeMediumTitle = medium.name || this.$t('allMedia')
      this.selectedMedium = medium.alias
      this._get()
    },
    filterBySearch() {
      this._get()
    },
    updatePagination(offset) {
      this.offset = offset
      this._get(false)
    },
    remove(id) {
      consolesService.remove({ id })
        .then(success => {
          this.$notify({
              message: this.$t('m.itemSuccessfullyDeleted', { item: this.$tc('console') }),
              icon: "far fa-bell",
              horizontalAlign: 'right',
              verticalAlign: 'top',
              type: 'success',
            })
          const index = this.items.findIndex(obj => obj.id === id)
          this.items.splice(index, 1)
        })
    }
	},
}
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="form-group col-12 text-right">
              <router-link :to="{ name: 'singleConsole', params: { id: 'new' } }">
                <BaseButton 
                  type="green"
                  gradient
                >
                  {{ $t('addNewItem', { item: $tc('console')}) }}
                </BaseButton>
              </router-link>
            </div>
          </div>
          <div class="row justify-content-between align-items-end">
            <div class="col-md-2">
              <label class="control-label">{{ $t('filterBy', { item: $tc('category') }) }}</label>
              <BaseDropdown
                title-classes="btn btn-secondary"
                :title="activeCategoryTitle"
              >
                <a
                  class="dropdown-item"
                  @click="filterByCategory('')"
                >{{ $t('allCategories') }}</a>
                <a
                  v-for="category of categories"
                  :key="category+'filter'+'category'"
                  class="dropdown-item"
                  @click="filterByCategory(category.alias)"
                >{{ category.name }}</a>
              </BaseDropdown>
            </div>
            <div class="col-md-2">
              <label class="control-label">{{ $t('filterBy', { item: $tc('type')}) }}</label>
              <BaseDropdown
                title-classes="btn btn-secondary"
                :title="activeTypeTitle"
              >
                <a
                  class="dropdown-item"
                  @click="filterByType('')"
                >{{ $t('allTypes') }}</a>
                <a
                  v-for="type of types"
                  :key="type+'filter'+'type'"
                  class="dropdown-item"
                  @click="filterByType(type)"
                >{{ type }}</a>
              </BaseDropdown>
            </div>
            <div class="col-md-2">
              <label class="control-label">{{ $t('filterBy', { item: $tc('brand') }) }}</label>
              <BaseDropdown
                title-classes="btn btn-secondary"
                :title="activeBrandTitle"
              >
                <a
                  class="dropdown-item"
                  @click="filterByBrand('')"
                >{{ $t('allBrands') }}</a>
                <a
                  v-for="brand of brands"
                  :key="brand.id+'filter'+'brand'"
                  class="dropdown-item"
                  @click="filterByBrand(brand)"
                >{{ brand.name }}</a>
              </BaseDropdown>
            </div>
            <div class="col-md-2">
              <label class="control-label">{{ $t('filterBy', { item: $tc('generation') }) }}</label>
              <BaseDropdown
                title-classes="btn btn-secondary"
                :title="activeGenerationTitle"
              >
                <a
                  class="dropdown-item"
                  @click="filterByGeneration('')"
                >{{ $t('allGenerations') }}</a>
                <a
                  v-for="generation of generations"
                  :key="generation.name+'filter'+'generation'"
                  class="dropdown-item"
                  @click="filterByGeneration(generation)"
                >{{ generation.name }}</a>
              </BaseDropdown>
            </div>
            <div class="col-md-2">
              <label class="control-label">{{ $t('filterBy', { item: $tc('medium', 2) }) }}</label>
              <BaseDropdown
                title-classes="btn btn-secondary"
                :title="activeMediumTitle"
              >
                <a
                  class="dropdown-item"
                  @click="filterByMedium('')"
                >{{ $t('allMedia') }}</a>
                <a
                  v-for="medium of media"
                  :key="medium.name+'filter'+'medium'"
                  class="dropdown-item"
                  @click="filterByMedium(medium)"
                >{{ medium.name }}</a>
              </BaseDropdown>
            </div>
            <div class="col-md-2">
              <BaseInput
                v-model="keyword"
                :placeholder="$t('search')"
                class="search"
                @keyup.prevent.stop="lazyType(filterBySearch, lazyTypeDelay)"
              />
            </div>
          </div>
        </div>
        <div class="card-body">
          <BaseListTable
            :items="items"
            :total-items="totalItems"
            :limit="limit"
            :offset="offset"
            :header-fields="headerFields"
            type="consoles"
            has-pagination
            @updatePagination="updatePagination"
            @delete="remove"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.search
  margin-bottom: $base-spacing / 4
</style>
